<template>
    <div>
        <h2 class="border-bottom pb-2 mb-3"><i class="fa fa-linux"></i> Proxy Manager</h2>


        <div class="row mb-12" v-if="loading" >
            <div class="col-12">
                <div class="card">
                    <div class="card-body text-center">
                        <b-spinner style="width: 3rem; height: 3rem;" type="grow" label="Processing......" variant="primary"></b-spinner>
                        <h4>Processing..............</h4>
                    </div>
                </div>
            </div>
        </div >
        <div style="background-color: white; padding:2%;" v-else>
            <div class="row listing" v-if="!editor">
                <div class="col-12">
                    <div class="row">
                        <div class="col-12">
                            <div class="form-inline">
                                <div class="form-group mr-1">
                                    <input type="text" v-model="filter.search" class="form-control" placeholder="Keyword Search"/>
                                </div>
                                <div class="form-group mr-1">
                                    <select v-model="filter.status" class="form-control">
                                        <option value="" selected>-- Any Status --</option>
                                        <option value="active">-- Active --</option>
                                        <option value="inactive">-- Inactive --</option>
                                    </select>
                                </div>
                                <div class="form-group mr-1">
                                    <select v-model="filter.targeting" class="form-control">
                                        <option value="" selected>-- Any Targeting --</option>
                                        <option value="nationwide">-- Nationwide --</option>
                                        <option value="state">-- State --</option>
                                        <option value="city">-- City --</option>
                                    </select>
                                </div>
                                <button type="button" class="btn text-white mr-1" style="background-color: #383838" @click="search"><i class="fa fa-search" title="Search"></i> Search</button>
                                <button type="button" class="btn text-white mr-1" style="background-color: #31e85b" @click="create"><i class="fa fa-plus" title="New"></i> Proxy Manager</button>
                            </div>
                        </div>
                    </div>
                    <hr/>

                    <div class="listing">
                        <ejs-grid
                            ref="grid"
                            id="Grid"
                            :dataSource="items"
                            :allowPaging="true"
                            :allowSorting="true"
                            :allowFiltering="true"
                            :filterSettings="filterSettings"
                            :pageSettings='pageSettings'
                        >
                            <e-columns>
                                <e-column field='name' headerText="Name"></e-column>
                                <e-column field='alias' headerText="System Alias"></e-column>
                                <e-column field='status' headerText='Status' :template="StatusTemplate"></e-column>
                                <e-column field='url' headerText='URL'></e-column>
                                <e-column field='username' headerText='Username'></e-column>
                                <e-column field='password' headerText='Password'></e-column>
                                <e-column field='targeting' headerText='Targeting' :template="TargetingTemplate"></e-column>
                                <e-column :template="ActionButtonsTemplate"></e-column>
                            </e-columns>
                        </ejs-grid>
                    </div>
                </div>
            </div>
            <div class="row editor" v-if="editor">
                <div class="col-12 offset-3 col-sm-6">
                    <div class="alert alert-info">
                        <h2>{{ editorTitle }}</h2>
                        <p>Enter information in the form below.</p>
                    </div>
                    <div class="card card-body">
                        <form class="form" role="form" style="" @submit.prevent="save">
                            <p><strong>Info</strong></p>
                            <div class="card card-body">
                                <div class="row">
                                    <div class="col-12 col-sm-6">
                                        <div class="form-group">
                                            <label>Name</label>
                                            <input type="text" v-model="item.name" class="form-control required" required/>
                                        </div>
                                        <div class="form-group">
                                            <label>Username</label>
                                            <input type="text" v-model="item.username" class="form-control"/>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="form-group">
                                            <label>System Alias</label>
                                            <input type="text" v-model="item.alias" class="form-control required" required/>
                                        </div>
                                        <div class="form-group">
                                            <label>Password</label>
                                            <input type="text" v-model="item.password" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-group">
                                            <label>URL</label>
                                            <input type="text" v-model="item.url" class="form-control required" required/>
                                        </div>
                                        <div class="form-group">
                                            <label>Api Key</label>
                                            <input type="text" v-model="item.api_key" class="form-control"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>
                            <p><strong>Targeting</strong></p>
                            <div class="card card-body">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="form-check form-check-inline mr-3">
                                            <label class="mr-2">Nationwide</label>
                                            <input type="checkbox"  v-model="item.targeting.nationwide">
                                        </div>
                                        <div class="form-check form-check-inline mr-3">
                                            <label class="mr-2">State</label>
                                            <input type="checkbox" v-model="item.targeting.state">
                                        </div>
                                        <div class="form-check form-check-inline mr-3">
                                            <label class="mr-2">City</label>
                                            <input type="checkbox" v-model="item.targeting.city">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-if="editorState === 'edit'">
                                <br>
                                <p><strong>Options</strong></p>
                                <div class="card card-body">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Status</label>
                                                <select class="form-control" v-model="item.status">
                                                    <option value="active">-- Active --</option>
                                                    <option value="inactive">-- Inactive --</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <br>

                            <div class="row">
                                <div class="col-12">
                                    <div class="form-group text-right">
                                        <button type="button" class="btn btn-secondary  btn-sm mr-2" @click="cancel"><span class="glyphicon glyphicon-remove"></span> Cancel</button>
                                        <button type="submit" class="btn btn-success btn-lg"><span class="glyphicon glyphicon-ok"></span> Save Changes</button>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <b-modal
                v-model="modalShow"
                size=""
                scrollable
                cancel-disabled
                :title="modalTitle"
            >
                <div class="row">
                    <div class="col-12">
                        <div class="card card-body">
                            <table class="table table-borderless table-b-table-default">
                                <tr>
                                    <td><strong>ID</strong></td>
                                    <td>{{ detailItem._id }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Name</strong></td>
                                    <td>{{ detailItem.name }}</td>
                                </tr>
                                <tr>
                                    <td><strong>System Alias</strong></td>
                                    <td>{{ detailItem.alias }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Status</strong></td>
                                    <td>{{ detailItem.status }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Url</strong></td>
                                    <td>{{ detailItem.url }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Username</strong></td>
                                    <td>{{ detailItem.username }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Password</strong></td>
                                    <td>{{ detailItem.password }}</td>
                                </tr>
                                <tr>
                                    <td><strong>API Key</strong></td>
                                    <td>{{ detailItem.api_key }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Nationwide Targeting</strong></td>
                                    <td>{{ detailItem.nationwide }}</td>
                                </tr>
                                <tr>
                                    <td><strong>State Targeting</strong></td>
                                    <td>{{ detailItem.state }}</td>
                                </tr>
                                <tr>
                                    <td><strong>City Targeting</strong></td>
                                    <td>{{ detailItem.city }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Date Created</strong></td>
                                    <td>{{ detailItem.date_created }}</td>
                                </tr>
                                <tr>
                                    <td><strong>Last Modified At</strong></td>
                                    <td>{{ detailItem.last_modified }}</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
                <template #modal-footer="{ ok }">
                    <b-button size="sm" variant="primary" @click="modalShow = false">
                        OK
                    </b-button>
                </template>
            </b-modal>
        </div>
    </div>
</template>

<script>
    import axios from 'axios'
    import swal from 'sweetalert'
    import Vue from "vue";
    import ProxyBus from '../busses/proxyBus'
    import pmTargetingTemplate from "@/components/pmTargetingTemplate";
    import pmStatusTemplate from "@/components/pmStatusTemplate";
    import pmActionButtonsTemplate from "@/components/pmActionButtonsTemplate";
    import {
        GridPlugin,
        Page,
        Sort,
        Filter
    } from "@syncfusion/ej2-vue-grids";

    Vue.use(GridPlugin);

    export default {
        name: 'ProxyManager',
        props:['user'],
        components: {

        },
        data: function () {
            return {
                filter:{
                    search:"",
                    status:"",
                    targeting:""
                },
                item:{
                    _id:null,
                    name:'',
                    url:'',
                    username:'',
                    password:'',
                    api_key:'',
                    targeting:{
                        nationwide:false,
                        state:false,
                        city:false
                    },
                    status:'active'
                },
                defItem:{
                    _id:null,
                    name:'',
                    url:'',
                    username:'',
                    password:'',
                    api_key:'',
                    targeting:{
                        nationwide:false,
                        state:false,
                        city:false
                    },
                    status:'active'
                },
                items:[],
                detailItem:{},
                editor: false,
                editorTitle:"",
                editorState: null,
                modalShow:false,
                modalTitle:'',
                loading:false,
                pageSettings: {pageSize: 100},
                filterSettings:{
                    immediateModeDelay:500,
                    mode:'Immediate'
                },
                StatusTemplate: function () {
                    return {
                        template : pmStatusTemplate
                    }
                },
                TargetingTemplate: function(){
                    return {
                        template: pmTargetingTemplate
                    }
                },
                ActionButtonsTemplate: function(){
                    return {
                        template: pmActionButtonsTemplate
                    }
                }
            }
        },
        created: function(){
            this.$root.isAuth();
            this.$root.preloader = false;
            ProxyBus.$on("editEvent", function(e){
                this.edit(e);
            }.bind(this));
            ProxyBus.$on("removeEvent",function(e){
                this.remove(e);
            }.bind(this));
            ProxyBus.$on("detailEvent", function(e){
                this.detail(e);
            }.bind(this));
            window.addEventListener("keydown",this.windowListener);
            this.search();
        },
        destroyed: function() {
            window.removeEventListener("keydown",this.windowListener);
        },
        provide: {
            grid: [
                Page,
                Sort,
                Filter
            ]
        },
        methods:{
            windowListener: function(e){
                if(e.keyCode === 13 && !this.editor && !this.searching){
                    this.search();
                }
            },
            create: function(){
                this.item = Object.assign({}, this.defItem);
                this.editor = true;
                this.editorTitle = "Add New Record";
                this.editorState = "create";
            },
            save: function(){
                //Handle saving the changes
                var request = {'apikey': this.$root.apikey, id:this.item._id, args:this.item};
                this.loading = true;

                axios({url:`${this.$root.serverUrl}/admin/proxy-manager`, data:request, responseType:'json', method:(this.editorState === "create")? 'POST' : 'PUT'}).then(function(resp){
                        //Process the results
                        if(resp.data && !resp.data.error){
                            //Saved successfully, now update the parent
                            swal({title:"Notice", text:"Your changes were saved successfully.", icon:"success"}).then(function(val){
                                if(val) {
                                    this.search();
                                    this.editor = false;
                                }
                            }.bind(this));
                        } else {
                            //Handle errors
                            this.errormsg = (resp.data && resp.data.error)? resp.data.error : false;
                        }
                    }.bind(this)).catch(function(err){
                        this.loading = false;
                        this.errormsg = err.message||"An unexpected error occurred.";
                        swal({title:"Error", text: this.errormsg, icon:"error"});
                    }.bind(this))
            },
            remove: function(obj){
                //Handle removing the item
                swal({title:"Remove", text:"Are you sure?", icon:"info", buttons:['No','Yes']}).then(function(val){
                    if(!val) return;
                    this.loading = true;
                    const request = {'apikey': this.$root.apikey, id:obj._id};

                    axios({url:`${this.$root.serverUrl}/admin/proxy-manager/${obj._id}`, data:request, responseType:'json', method:'DELETE'}).then(function(resp){
                           this.loading = false;
                            if(resp.data && !resp.data.error){
                                //Saved successfully, now update the parent
                                swal({title:"Remove", text:"Your request was completed successfully.", icon:"success"}).then(function(){
                                    this.search();
                                }.bind(this));
                            } else {
                                //Handle errors
                                swal({title:"Remove", text:"Unable to complete your request.", icon:"error"});
                            }
                        }.bind(this))
                        .catch(function(err){
                           this.loading = false;
                            this.errormsg = err.message||"An unexpected error occurred";
                            swal({title:"Error", text: this.errormsg, icon:"error"});
                        }.bind(this))
                    }.bind(this));
            },
            edit: function(obj){
                this.editor = true;
                this.item = Object.clone({}, obj);
                this.editorTitle = `Edit Existing Record (${obj.name})`;
                this.editorState = "edit";
            },
            detail: function(obj){
                this.detailItem = Object.assign({}, obj);
                this.detailItem.date_created = new Date(this.detailItem.date_created).toLocaleDateString();
                this.detailItem.last_modified = new Date(this.detailItem.last_modified).toLocaleDateString();
                this.modalTitle = `Details (${obj.name})`
                this.modalShow = true;
            },
            cancel: function(){
                this.editor = false;
                this.errormsg = false;
            },
            search: function(){
                this.searching = true;
                this.loading = true;
                //Load the items

                var request = {'apikey': this.$root.apikey, sSearch:null, filters:{}};
                if(this.filter.search) request.filters.search = this.filter.search;
                if(this.filter.status) request.filters.status = this.filter.status;
                if(this.filter.targeting) request.filters.targeting = this.filter.targeting;

                axios.get(`${this.$root.serverUrl}/admin/proxy-manager`, {params:request}).then(function(resp){
                    this.items = (resp.data.data)? resp.data.data : [];
                    this.searching = false;
                    this.loading = false;
                }.bind(this));
            }
        }
    }
</script>

<style>

</style>
