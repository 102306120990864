<template>
<div>
    <span v-if="data.status!='active'" class="badge badge-danger">{{data.status}}</span>
    <span v-else class="badge badge-success">{{data.status}}</span>
</div>
</template>

<script>
export default {
    name: "pmStatusTemplate",
    data: function(){
        return {
            data:{}
        }
    }
}
</script>

<style scoped>

</style>
