<template>
    <div class="btn-group icons">
        <button class="btn btn-info" @click="pmDetail(data)" ><span class="fa fa-eye" title="View"></span></button>
        <button class="btn btn-primary" @click="pmEdit(data)" ><span class="fa fa-pencil" title="View"></span></button>
        <button class="btn btn-danger" @click="pmRemove(data)"><span class="fa fa-trash" title="Delete"></span></button>
    </div>
</template>

<script>
import ProxyBus from '../busses/proxyBus'
export default {
    name: "pmActionButtonsTemplate",
    data: function(){
        return {
            data: {},
        }
    },
    methods:{
        pmEdit: function(data){
           ProxyBus.$emit("editEvent",{
               _id: data._id,
               name: data.name,
               alias: data.alias,
               username: data.username,
               password: data.password,
               url: data.url,
               targeting: data.targeting,
               status: data.status,
               api_key: data.api_key
           });
        },
        pmRemove: function(data){
            ProxyBus.$emit("removeEvent",{
                _id: data._id,
                name: data.name,
                alias: data.alias,
                username: data.username,
                password: data.password,
                url: data.url,
                targeting: data.targeting,
                status: data.status,
                api_key: data.api_key
            });
        },
        pmDetail: function(data){
            ProxyBus.$emit("detailEvent",{
                _id: data._id,
                name: data.name,
                alias: data.alias,
                username: data.username,
                password: data.password,
                url: data.url,
                targeting: data.targeting,
                status: data.status,
                api_key: (data.api_key.length > 0) ? data.api_key : "N/A",
                date_created: data.created_ts,
                last_modified: data.modified_ts,
                nationwide: (data.targeting.nationwide) ? "Yes" : "No",
                state: (data.targeting.state) ? "Yes" : "No",
                city: (data.targeting.city) ? "Yes" : "No"
            })
        }
    }
}
</script>

<style scoped>

</style>
