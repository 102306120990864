import { render, staticRenderFns } from "./pmStatusTemplate.vue?vue&type=template&id=60e1890a&scoped=true&"
import script from "./pmStatusTemplate.vue?vue&type=script&lang=js&"
export * from "./pmStatusTemplate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e1890a",
  null
  
)

export default component.exports