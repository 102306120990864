<template>
    <div>
        <span v-if="data.targeting.nationwide === true" class="badge badge-success">N</span>
        <span v-else class="badge badge-danger">N</span>
        <span v-if="data.targeting.state === true" class="badge badge-success">S</span>
        <span v-else class="badge badge-danger">S</span>
        <span v-if="data.targeting.city === true" class="badge badge-success">C</span>
        <span v-else class="badge badge-danger">C</span>
    </div>
</template>

<script>
export default {
    name: "pmTargetingTemplate",
    data: function(){
        return {
            data: {}
        }
    }
}
</script>

<style scoped>

</style>
